import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SocialConnect from "../components/ConnectSocial";

// import EventCalendar from 'react-event-calendar';


// import Calendar from "react-awesome-calendar";


const hedd= {
    mode:"monthlyMode",
}

const About = ({ data }) => (
  <Layout title={"Pestgogo Calender"}>
    <div className="about">
      <div
        className="hero2"
      >
        <h1>Pestgogo Calender</h1>
      </div>

          

      <div className="ssbody wrapper_max">
        <div className="content calendarContent">
         {/* <Calendar */}
            {/* />
             */}
             {/* <EventCalendar/> */}

          <SocialConnect />
        </div>
      </div>
    </div>
  </Layout>
);

export default About;
